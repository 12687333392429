<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="confirm" />
    <base-snackbar-notification ref="mainSnackbar" />
    <v-row align="center" justify="center" v-if="showCheckInButton">
      <v-col cols="12">
        <v-dialog v-model="showCheckInModal" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              class="fairwage_dark_grey--text rounded-lg"
              elevation="0"
              block
              :style="{ height: '80px' }"
              v-bind="attrs"
              v-on="on"
            >Log Work</v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h3">
              Check in to work
            </v-card-title>
            <v-card-text>
              By clicking <span class="font-weight-bold primary--text">LOG WORK</span> you agree that you are at work and are willing
              and able to do the task's instructed to you by your employer.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="warning" class="my-2 mx-4" @click="closeCheckInModal()">Cancel</v-btn>
              <v-form ref="userForm" @submit.prevent="logWorkEvent()">
                <v-btn color="primary" class="my-2" @click="logWorkEvent()" elevation="0">Log Work</v-btn>
              </v-form>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <template v-if="showToDoListButton">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-btn
            color="secondary"
            class="fairwage_dark_grey--text rounded-lg"
            elevation="0"
            block
            :style="{ height: '80px' }"
            @click="$router.push('/user/todo-list')"
          ><v-spacer></v-spacer><p class="mb-0">To Do list</p>
            <v-spacer></v-spacer>
            <v-icon
              class=""
              dark
              large
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="showShoppingListButton">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-btn
            elevation="0"
            block
            color="secondary"
            class="fairwage_dark_grey--text rounded-lg"
            :style="{ height: '80px' }"
            @click="$router.push('/user/shopping-list')"
          ><v-spacer></v-spacer>Shopping List
            <v-spacer></v-spacer>
            <v-icon
                class=""
                dark
                large
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn
            elevation="0"
            block
            color="secondary"
            class="fairwage_dark_grey--text rounded-lg"
          :style="{ height: '80px' }"
          @click='$router.push({ name: "userCalender", params: { contractModel: contractModel }})'>
        <v-spacer></v-spacer>Days worked
          <v-spacer></v-spacer>
          <v-icon
              class=""
              dark
              large
          >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-btn
            elevation="0"
            block
            color="secondary"
            class="fairwage_dark_grey--text rounded-lg"
            :style="{ height: '80px' }"
            @click="$router.push('/user/paymentHistory')">
          <v-spacer></v-spacer>View Payment History
          <v-spacer></v-spacer>
          <v-icon
            class=""
            dark
            large
          >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="userModel.contractHtml !== ''">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-dialog v-model="mainContractDialog" width="500">


            <v-card>
              <v-card-title class="text-h5 white--text primary">
                Your Employment Contract
              </v-card-title>

              <v-card-text>
                <pdf
                    style="border: 1px solid #333"
                    class="mt-4"
                    :src="userModel.contractHtml"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                ></pdf>
                <v-col cols="6" class="mt-1 py-0">
                  {{ currentPage }} / {{ pageCount }}
                </v-col>
              </v-card-text>
              <v-card-actions
                  class="justify-end pb-4"
              >
                <v-btn
                    color="warning"
                    text
                    @click="mainContractDialog = false"
                >
                  CANCEL
                </v-btn>
                <v-btn
                    color="primary"
                    elevation="0"
                    :href="userModel.contractHtml"
                    target="_blank"
                    class=""
                >
                  Download
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
              elevation="0"
              block
              color="secondary"
              class="fairwage_dark_grey--text rounded-lg"
              :style="{ height: '80px' }"
              @click="clickContractDialog()"
          ><v-spacer></v-spacer>
            Contract
            <v-spacer></v-spacer>
            <v-icon
                class=""
                dark
                large
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>



  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import pdf from "vue-pdf";
import UserCreate from "../../components/Base/UserCreate.vue";

export default {
  components: { UserCreate, pdf },
  name: "Home",
  data() {
    return {
      show: false,
      specifiedRequestType: "",
      currentPage: 0,
      pageCount: 0,
      menu: false,
      showCheckInModal: false,
      mainContractDialog: false,
      isLoading: false,
      loadingContractDocument: false,
      isEdit: false,
      dialog: false,
      showToDoListButton: false,
      showShoppingListButton: false,
      mainPayslipDialog: false,
      showCheckInButton: false,
      viewPayslipDialog: false,
      viewContractDialog: false,
      contractModel: {},
      userModel: {
        id: "",
        role: "",
        firstName: "",
        lastName: "",
        telNumber: "",
        email: "",
        idNumber: "",
        accountHolder: "",
        accountType: "",
        bankName: "",
        branchCode: "",
        accountNumber: "",
        fullAddress: "",
        profileStatus: false,
        updatedBy: "",
        updatedByName: "",
        payslipHtml: "",
        contractHtml: "",
        jobResponsibilities: [],
        netPay: "",
        uif: "",
      },
    };
  },
  created() {
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {},

  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            email
            telNumber
            role
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        if (this.me.id != "" || null) {
          this.isEdit = true;
          this.id = this.me.id;

          this.getContractByUserId(this.me.id).then((result) => {
            this.contractModel = result
            if (this.contractModel.wageTracking !== 'automatic' && this.contractModel.wageTracking !== 'employer_must_track') {
              this.getCheckinForToday()
            }
          }).catch((error) => {
            console.error(error)
          })
        }
      },
    },
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            telNumber
            bankName
            branchCode
            accountType
            accountNumber
            accountHolder
            email
            passport
            idNumber
            role
            fullAddress
            profileStatus
            payslipHtml
            contractHtml
            updatedBy
            updatedByName
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        return {
          id: this.id,
        };
      },
      result(response) {
        if (response.data.user.id != "" || null) {
          this.userModel.id = this.user.id;
          this.userModel.role = this.user.role;
          this.userModel.firstName = this.user.firstName;
          this.userModel.lastName = this.user.lastName;
          this.userModel.telNumber = this.user.telNumber;
          this.userModel.email = this.user.email;
          this.userModel.idNumber = this.user.idNumber;
          this.userModel.fullAddress = this.user.fullAddress;
          this.userModel.passport = this.user.passport;
          this.userModel.bankName = this.user.bankName;
          this.userModel.branchCode = this.user.branchCode;
          this.userModel.accountType = this.user.accountType;
          this.userModel.accountNumber = this.user.accountNumber;
          this.userModel.accountHolder = this.user.accountHolder;
          this.userModel.profileStatus = this.user.profileStatus;
          this.userModel.updatedBy = this.me.id;
          this.userModel.updatedByName = this.user.updatedByName;
          this.userModel.payslipHtml = this.user.payslipHtml;
          this.userModel.contractHtml = this.user.contractHtml;
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
      },
    }
  },
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  methods: {
    async getCheckinForToday () {
      await this.$apollo.query({
        query: gql`
          query workEvent($id: ID! $eventDate: Float!) {
            workEvent(id: $id eventDate: $eventDate) {
              id
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
          id: this.id,
          eventDate: new Date().getTime()
        },
      }).then((result) => {
        if (_.has(result, 'data.workEvent.id')) {
          this.showCheckInModal = false;
          this.showCheckInButton = false;
        } else {
          this.showCheckInModal = false;
          this.showCheckInButton = true;
        }
      })
      .catch((e) => {
        this.isLoading = false;
        this.showCheckInModal = false;
        this.$refs.confirm.open("Error", "Error determining if you have Checked In today. Please try again or contact support.", "error")
      })
    },
    closeCheckInModal() {
      // set the flag to hide the modal
      this.showCheckInModal = false;
    },
    async refetchData() {
      this.$apollo.queries.user.refetch();
    },
    async clickContractDialog() {
      this.isLoading = true
      this.$apollo.queries.user.refetch().then((result) => {
        this.isLoading = false
        this.mainContractDialog = true;
      }).catch((error) => {
        this.isLoading = false
        console.error(error)
      })
    },
    async signLeaseAgreement() {
      const self = this;
      self.isLoading = false;
      self.successMessage = "";
      self.errorMessage = "";
      console.log("specifiedRequestType", this.specifiedRequestType);
      if (this.me) {
        try {
          self.isLoading = true;
          const getQuestionsBeforeYouSignAgreementLink =
            await this.$apollo.mutate({
              mutation: gql`
                mutation getQuestionsBeforeYouSignAgreementLink(
                  $telNumber: String!
                  $specifiedRequestType: String!
                ) {
                  getQuestionsBeforeYouSignAgreementLink(
                    telNumber: $telNumber
                    specifiedRequestType: $specifiedRequestType
                  )
                }
              `,
              variables: {
                telNumber: this.me.telNumber,
                specifiedRequestType: this.specifiedRequestType,
              },
            });
          if (getQuestionsBeforeYouSignAgreementLink) {
            // self.successMessage = `Lease Agreement Link sent to ${this.me.email}`;
            this.$swal(
              "Sent!",
              `Request of ${this.specifiedRequestType} has been set to ${this.me.email}`,
              "success"
            ).then(() => {
              if (this.specifiedRequestType == "payslip") {
                self.viewPayslipDialog = true;
                self.mainPayslipDialog = false;
                refetchData();
              }

              if (this.specifiedRequestType == "contract") {
                self.viewContractDialog = true;
                self.mainContractDialog = false;
                refetchData();
              }
            });
          }
          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            this.$swal(`Something went wong: ${error.response.data}`);
            self.errorMessage = `${error.response.data}`;
          } else {
            this.$swal(`Something went wong: ${error.message}`);
          }
          self.isLoading = false;
        }
      } else {
        this.$swal(
          `Unable to process the request of ${this.specifiedRequestType}`
        );
        self.errorMessage = "Unable to process contract";
        self.isLoading = false;
      }
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              gender: self.userModel.gender,
              telNumber: self.userModel.telNumber,
              email: self.userModel.email,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              bankName: self.userModel.bankName,
              branchCode: self.userModel.branchCode,
              accountHolder: self.userModel.accountHolder,
              accountNumber: self.userModel.accountNumber,
              accountType: self.userModel.accountType,
              role: "employee",
              updatedBy: self.me.id,
              updatedByName: self.userModel.updatedByName,
              profileStatus: self.userModel.profileStatus,
              payslipHtml: self.userModel.payslipHtml,
              contractHtml: self.userModel.contractHtml,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when updating your data!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message
      }
    },
    async logWorkEvent() {
      const self = this;
      const workEventObj = this.returnWorkEventLog(this.me.id, 'WORK', this.me.id, new Date().getTime())

      await self.$apollo.mutate({
        mutation: gql`
            mutation createWorkEvent($workEvent: WorkEventInput!) {
              createWorkEvent(workEvent: $workEvent) {
                id
              }
            }
          `,
        variables: {
          workEvent: workEventObj,
        },
      }).then(() => {
        this.isLoading = false;
        this.showCheckInModal = false;
        this.showCheckInButton = false;
        this.$refs.mainSnackbar.open("You have successfully logged work.", "success")
      })
      .catch((e) => {
        this.isLoading = false;
        this.showCheckInModal = false;
        this.$refs.confirm.open("Error", "There was a problem logging your work check in. Please try again or contact support.", "error")
      })
    },
    returnWorkEventLog (assignedTo, eventType, createdBy, eventDate) {
      if (!eventDate) eventDate = new Date().getTime()
      const createdAt = new Date().getTime()
      const workEventObj = {
        assignedTo: assignedTo,
        eventType: eventType,
        eventUnit: this.contractModel.wagePeriod,
        createdBy: createdBy,
        createdAt: createdAt,
        eventDate: eventDate,
      }
      return workEventObj
    },
    async getContractByUserId (userId) {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true
        this.$apollo.query({
          query: gql`
            query GetContract($userId: ID!) {
              contractByUserId(userId: $userId) {
                id
                userId
                jobTitle
                duties
                contractCurrency
                terminationNoticePeriod
                wagePaymentSchedule
                wagePaymentDate
                wagePaymentWeekDay
                wagePeriod
                wagePeriodUnitType
                wageTracking
                wageRate
                overtimeAvailable
                overtimeRate
                publicHolidayRate
                hoursOfWorkStart
                hoursOfWorkEnd
                lunchPeriod
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            userId: userId
          }
        }).then((data) => {
          this.isLoading = false
          if (_.has(data, 'data.contractByUserId.id')) {
            return resolve(data.data.contractByUserId)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.log('Error:', error)
          this.isLoading = false
          return reject(error)
        })
      })
    },
  },
};
</script>

<style scoped></style>
